import _ from "lodash";
import { EXTENSIONS } from "tracker-api/types";

const DEFAULT_ENTITY_PROPS = {
  completed: false,
  noOfCorrectAnswers: 0,
  noOfIncorrectAnswers: 0,
  success: false,
  verified: false
};

const correctOrIncorrectIncr = (entity, success) => {
  return {
    noOfCorrectAnswers: success
      ? entity.noOfCorrectAnswers + 1
      : entity.noOfCorrectAnswers,
    noOfIncorrectAnswers: !success
      ? entity.noOfIncorrectAnswers + 1
      : entity.noOfIncorrectAnswers
  };
};

const isPlaceholderId = id => {
  return id.indexOf("placeholdertg") !== -1;
};

export function getCourseInitialized(input, oldState, is2004) {
  if (oldState && !_.isEmpty(oldState)) return oldState;
  return {
    courseTitle: input.object.title,
    sections: input.object.extensions[EXTENSIONS.COURSESTRUCTURE].sections.map(
      section => {
        return {
          ...section,
          ...DEFAULT_ENTITY_PROPS,
          title: is2004 ? section.title : "", // must save precious bytes
          chaptersCount: section.chapters.length,
          completedChaptersCount: 0,
          successChaptersCount: 0,
          chapters: section.chapters.map(chapter => {
            return {
              ...chapter,
              ...DEFAULT_ENTITY_PROPS,
              title: is2004 ? chapter.title : "", // must save precious bytes
              taskGroups: chapter.taskGroups.map(taskGroup => {
                return {
                  ...taskGroup,
                  ...DEFAULT_ENTITY_PROPS,
                  isPlaceholder: isPlaceholderId(taskGroup.id),
                  tasks: []
                };
              })
            };
          })
        };
      }
    )
  };
}

export function getSectionStarted(input, oldState) {
  // Nothing visible in state to change
  return oldState;
}

export function getSectionAnswered(input, oldState) {
  // Noting visible in state to change
  return oldState;
}

export function getSectionRestarted(input, oldState) {
  return {
    ...oldState,
    sections: oldState.sections.map(section => {
      if (section.id === input.object.id) {
        const _chapters = section.chapters.map(chapter => {
          return {
            ...chapter,
            verified: false,
            completed: chapter.success, // When restarting, only the failed chapters will be expected to be completed again
            taskGroups: chapter.taskGroups.map(taskGroup => {
              // Also, only the failed taskgroups within the
              // chapter will be expected to be completed again
              if (!taskGroup.success) {
                return {
                  ...taskGroup,
                  verified: false,
                  success: false,
                  completed: false
                };
              }
              return taskGroup;
            })
          };
        });
        return {
          ...section,
          verified: false,
          success: false,
          completed: false,
          chapters: _chapters,
          completedChaptersCount: _chapters.filter(c => c.completed).length,
          successChaptersCount: _chapters.filter(c => c.success).length
        };
      }
      return section;
    })
  };
}

export function getChapterStarted(input, oldState) {
  // Nothing visible in state to change
  return oldState;
}

export function getChapterAnswered(input, oldState) {
  // Nothing visible in state to change
  return oldState;
}

export function getTaskStarted(input, oldState, is2004) {
  const chapterId = input.context.chapter;
  const { taskGroupId, id, taskType, title } = input.object;
  return {
    ...oldState,
    sections: oldState.sections.map(section => {
      const foundChapter = _.find(section.chapters, c => c.id === chapterId);
      if (foundChapter) {
        return {
          ...section,
          chapters: section.chapters.map(chapter => {
            const foundTaskGroup = _.find(
              chapter.taskGroups,
              tg => tg.id === taskGroupId
            );
            if (foundTaskGroup) {
              return {
                ...chapter,
                taskGroups: chapter.taskGroups.map(taskGroup => {
                  if (taskGroup.id === taskGroupId) {
                    const newTask = {
                      completed: false,
                      id,
                      options: null,
                      restarted: false,
                      sequentSteps: [],
                      success: false,
                      taskType,
                      title: is2004 ? title : "",
                      touched: Date.now(),
                      verified: false
                    };
                    return {
                      ...taskGroup,
                      // Add the new instance of task first,
                      // so they are in descending order by 'touched'.

                      // SCORM 1.2 only supports ~4000 characters of data
                      // when saving suspend data, so we can't track all
                      // attempts in that case :(
                      tasks: is2004 ? [newTask, ...taskGroup.tasks] : [newTask]
                    };
                  }
                  return taskGroup;
                })
              };
            }
            return chapter;
          })
        };
      }
      return section;
    })
  };

}

export function getTaskAnswered(input, oldState, is2004) {
  let _input = { ...input };
  if (!_input.result) _input.result = {};
  const chapterId = _input.context.chapter;
  const { taskGroupId, id } = _input.object;
  const { options, sequentSteps, matches } = _input.result;
  const isPlaceholderTask = isPlaceholderId(taskGroupId);
  const success = isPlaceholderTask ? true : _input.result.success;
  const withTaskMutations = {
    ...oldState,
    sections: oldState.sections.map(section => {
      const foundChapter = _.find(section.chapters, c => c.id === chapterId);
      if (foundChapter) {
        return {
          ...section,
          ...correctOrIncorrectIncr(section, success),
          chapters: section.chapters.map(chapter => {
            const foundTaskGroup = _.find(
              chapter.taskGroups,
              tg => tg.id === taskGroupId
            );
            if (foundTaskGroup) {
              return {
                ...chapter,
                ...correctOrIncorrectIncr(chapter, success),
                taskGroups: chapter.taskGroups.map(taskGroup => {
                  if (taskGroup.id === taskGroupId) {
                    let taskIdFound = false;
                    return {
                      ...taskGroup,
                      ...correctOrIncorrectIncr(taskGroup, success),
                      completed: true,
                      success,
                      verified: isPlaceholderTask ? true : taskGroup.verified,
                      // Change the first instance of task
                      // that is not completed
                      tasks: taskGroup.tasks.map(task => {
                        if (task.id === id && !taskIdFound && !task.completed) {
                          taskIdFound = true;
                          return {
                            ...task,
                            completed: true,
                            options: is2004 ? options : task.options, // null if 1.2
                            matches: is2004 ? matches : task.matches, // null if 1.2
                            sequentSteps: is2004
                              ? sequentSteps
                              : task.sequentSteps, // empty array if 1.2
                            success,
                            touched: Date.now()
                          };
                        }
                        return task;
                      })
                    };
                  }
                  return taskGroup;
                })
              };
            }
            return chapter;
          })
        };
      }
      return section;
    })
  };

  // Finish the new state by adding the
  // summing properties to the section and chapter levels.
  return {
    ...withTaskMutations,
    sections: withTaskMutations.sections.map(section => {
      const chapters = section.chapters.map(chapter => {
        return {
          ...chapter,
          completed: chapter.taskGroups.filter(c => !c.completed).length === 0,
          success: chapter.taskGroups.filter(c => !c.success).length === 0,
          verified: chapter.taskGroups.filter(c => !c.verified).length === 0,
        }
      });
      return {
        ...section,
        chapters,
        completed: chapters.filter(c => !c.completed).length === 0,
        success: chapters.filter(c => !c.success).length === 0,
        verified: chapters.filter(c => !c.verified).length === 0,
        completedChaptersCount: chapters.filter(c => c.completed).length,
        successChaptersCount: chapters.filter(c => c.success).length,
      }
    })
  }
}

export function getSectionVerified(input, oldState) {
  return {
    ...oldState,
    sections: oldState.sections.map(section => {
      if (section.id === input.object.id) {
        return {
          ...section,
          verified: true,
          chapters: section.chapters.map(chapter => {
            return {
              ...chapter,
              verified: true,
              taskGroups: chapter.taskGroups.map(taskGroup => {
                // TODO something special for tasks here?
                return {
                  ...taskGroup,
                  verified: true
                };
              })
            };
          })
        };
      }
      return section;
    })
  };
}
