import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18n";
import { useParams } from "react-router-dom";

import { IS_SCORM } from "consts";
import {
  setLanguageWasSetFromCourse,
  setTheme,
  setCourseDataCache,
  setCourseAcfTasksDataCache,
  setCourseDataLoading,
  setCurrentLanguage,
  setCourseDataVersion
} from "actions";

import getCurrentLangSlug from "utils/getCurrentLangSlug";
import sectionHasTasks from "utils/sectionHasTasks";
import debugLog from "utils/debugLog";
import envConfig from "environment.json";

function useCurrentCourse() {
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const courseDataCache = useSelector(
    state => state.course.courseDataCache
  );
  const courseAcfTasksDataCache = useSelector(
    state => state.course.courseAcfTasksDataCache
  );
  const courseDataVersion = useSelector(
    state => state.course.courseDataVersion
  );
  const courseDataLoading = useSelector(
    state => state.course.courseDataLoading
  );
  const [isOkToFetch, setIsOkToFetch] = useState(false);
  const [version, setVersion] = useState(null);

  const [courseDataError, setCourseDataError] = useState(null);

  const languageWasSetFromCourse = useSelector(
    state => state.layout.languageWasSetFromCourse
  );
  const didSetTheme = useSelector(state => state.layout.didSetTheme);

  const data = courseDataCache;
  const loading = courseDataLoading;
  const error = courseDataError;

  useEffect(() => {
    const _getCourseData = async () => {
      dispatch(setCourseDataLoading(true));
      const url = !IS_SCORM ? `${envConfig.CMS_BASE_URI}/wp-json/courseversion/${courseSlug}?v=${version}` : "courseContent.json";
      const res = await fetch(url, {
        method: "GET"
      })
        .then(_res => {
          return _res.json();
        })
        .then(json => {
          let _data = json;
          let acfTasksData = null;

          _data = json.data;
          
          acfTasksData = json.acf_task_data;
          document.title = _data.course.titleRendered;
          dispatch(setCourseDataCache(_data));
          dispatch(setCourseAcfTasksDataCache(acfTasksData));
          dispatch(setCourseDataVersion(json.v));
          dispatch(setCourseDataLoading(false));

        })
        .catch(err => {
          console.error(err);
          setCourseDataError(err);
          dispatch(setCourseDataLoading(false));
        });
      return res;
    };

    if (!data && !loading && !error && isOkToFetch) {
      _getCourseData();
    }
  }, [dispatch, version, isOkToFetch, courseSlug, data, loading, error]);

  const fetchData = useCallback((version) => {
    setVersion(version);
    setIsOkToFetch(true);
  }, [setIsOkToFetch, setVersion]);

  // Get and set the theme for this course
  useEffect(() => {
    if (
      data &&
      data.course &&
      data.course.blbGroupCourseTheme &&
      data.course.blbGroupCourseTheme.blbCourseTheme &&
      !didSetTheme
    ) {
      const themeToSet = data.course.blbGroupCourseTheme.blbCourseTheme;
      debugLog("Setting theme", themeToSet);
      dispatch(setTheme(themeToSet));
    }
  }, [data, dispatch, didSetTheme]);

  // Get and set the lang for this course
  useEffect(() => {
    if (data) {
      let courseLanguage = getCurrentLangSlug(data);

      if (!languageWasSetFromCourse) {
        if (!courseLanguage) {
          console.warn(
            "There is no language set for this course! Defaulting to sv."
          );
          courseLanguage = "sv";
        }
        i18n.changeLanguage(courseLanguage, (err, t) => {
          if (err) return debugLog("Failed setting language", courseLanguage);
          return debugLog("Set language to", courseLanguage);
        });
        dispatch(setLanguageWasSetFromCourse());
      }
      dispatch(setCurrentLanguage(courseLanguage));
    }
  }, [data, languageWasSetFromCourse, dispatch]);

  if (loading) debugLog("Loading course");
  if (error) debugLog("Error loading course");

  // debugLog("Apollo query networkStatus", networkStatus);

  const courseHasTasks =
    data && data.course
      ? data.course.blbGroupCourseSections.blbCourseSections.filter(section =>
          sectionHasTasks(section)
        ).length > 0
      : false;

  return {
    loading,
    error,
    data,
    acfTasksData: courseAcfTasksDataCache,
    version: courseDataVersion,
    courseHasTasks,
    fetchData
  };
}

export default useCurrentCourse;
