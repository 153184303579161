import React, { useState, useEffect, useMemo } from "react";

import Loading from "components/Loading/Loading";
import TaskHeader from "components/TaskHeader/TaskHeader";
import TaskInitialContent from "components/TaskInitialContent/TaskInitialContent";
import TaskInteractionWrapper from "components/TaskInteractionWrapper/TaskInteractionWrapper";
import TaskOptionFeedback from "components/TaskOptionFeedback/TaskOptionFeedback";

import useCurrentCourse from "hooks/useCurrentCourse";

import getTaskType from "utils/getTaskType";

import debugLog from "utils/debugLog";
import { useTranslation } from "react-i18next";
import TaskFinalFeedback from "components/TaskFinalFeedback/TaskFinalFeedback";

function AcfTask(props) {
  const { block } = props;
  const [selectedValues, setSelectedValues] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false)
  const [hideComponent, setHideComponent] = useState(false)


  const taskId = block && block.dynamicContent ? block.dynamicContent : null;

  debugLog("AcfTask taskId", taskId);

  const { loading, error, acfTasksData } = useCurrentCourse();
  const { t } = useTranslation();

  const data = useMemo(() => {
    if(!acfTasksData) return null;
    const taskData = Object.values(acfTasksData).find(_taskData => {
      return _taskData.id === taskId;
    });
    return taskData?.data || null;
  }, [taskId, acfTasksData]);



  if (loading) return <Loading />;
  if (error) console.error(error);
  if (

    error ||
    (!data || (data && !data.task))
  )
    return null;

  const taskData = data.task;

  const type = getTaskType(taskData);

  const hasSelected = !!selectedValues.length;
  const resetTask = () => {
    setHideComponent(true);
    setTimeout(() => {
      setHideComponent(false)
      setShowAnswer(false)
      setSelectedValues([])
    }, 1)
  }
  return (
    <div className="Fact-block--AcfTask">
      <TaskHeader taskData={taskData} isInFacts={true} />
      {!hasSelected ? <TaskInitialContent taskData={taskData} /> : null}

      <TaskOptionFeedback
        taskData={taskData}
        selectedValues={selectedValues}
        type={type}
      />

      {showAnswer && taskData.blbGroupTaskOptions?.blbTaskFeedback?.length
        && <div className="Fact-block--BucLmsBuilderModal--feedback">
              <TaskFinalFeedback feedback={taskData.blbGroupTaskOptions.blbTaskFeedback[0]}></TaskFinalFeedback>
            </div>
      }

      {!hideComponent ? <TaskInteractionWrapper
        taskData={taskData}
        selectedValues={selectedValues}
        selectFn={setSelectedValues}
        type={type}
        isInFacts={true}
        currentTaskStep={-1}
        showAnswer={showAnswer}
      /> : null}
        <div className="Fact-block--BucLmsBuilderModal--aligncenter">
          {showAnswer ? 
            <button onClick={resetTask}>{t("RESET_ANSWERS")}</button> :
            <button onClick={(e) => setShowAnswer(true)} disabled={!selectedValues.length}>{t("CORRECT_ANSWER")}</button>
          }
        </div>
    </div>
  );
}

export default AcfTask;
